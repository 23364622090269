a.cart-dropdown {
    display: block;
    //background: url(/sites/all/themes/zenia/img/cart.png) no-repeat left center;
    height: 35px;
    line-height: 35px;
    padding-left: 40px;
    float: right;
}

.view-small-shopping-cart .view-header {
    text-align: right;
    overflow: auto;
    padding-right: 28px;
    .line-item-summary {
      margin: 0;
    }
    .line-item-summary .line-item-quantity {
      float:right;
      margin-left: 10px;
      .line-item-quantity-label {
        display:none;
      }
    }
    .line-item-summary-view-cart {
      a {
        font-size: 0;
        display: inline-block;
        vertical-align: top;
        background: url('../images/shoppingbag.png') no-repeat right 0;
        width: 43px;
        height: 30px;
        &:hover {
          cursor:pointer;
        }
      }
    }

    .line-item-summary .links {
      float: left;
      margin: 0;
      padding: 0;
    }
}

.view-small-shopping-cart .view-box table {
    border: 0;
    > tbody > tr > td {
      border: 0;
    }
}

.view-small-shopping-cart {
  position:relative;
  .view-box {
    width: 400px;
    display: none;
    background-color: #fff;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(107,107,107,1);
    -moz-box-shadow: 0px 3px 10px 0px rgba(107,107,107,1);
    box-shadow: 0px 3px 10px 0px rgba(107,107,107,1);
    clear: both;
    position: absolute;
    right: 0;
    top: 63px;
    z-index: 500;
    .view-content {
      margin-left: 25px;
      margin-right: 25px;
      padding-top: 25px;
      border-bottom: 1px solid #ebebf5;
    }
    .view-footer {
      margin-left: 25px;
      margin-right: 25px;
      .line-item-quantity {
        padding-top: 15px;
        padding-bottom: 25px;
      }
      .line-item-total {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #ebebf5;
        margin-bottom: 10px;
      }
      .line-item-summary {
        margin: 0;
      }
      .list-inline {
        display:table;
        width: 100%;
        margin: 0;
        padding-bottom: 25px;
        > li {
          &.line-item-summary-view-cart {
            a {
              display: block;
              text-decoration: none;
              .btn;
              .btn-default;
              .btn-lg;
            }
          }
          &.line-item-summary-checkout {
            a {
              display: block;
              text-decoration: none;
              .btn;
              .btn-default;
              .btn-lg;
            }
          }
        }
      }
    }
  }
}

#block-views-small-shopping-cart-block .cart-empty-block {
  span {
      background: url('../images/shoppingbag.png') no-repeat left 0;
      display: inline-block;
      vertical-align: top;
      margin-left: 30px;
      width: 34px;
      height: 30px;
      text-align:right;
      margin-right: 30px;
  }
}
