// Bootstrap library.
@import 'bootstrap';

// Base-theme overrides.
@import 'overrides';

@import 'blockcart';

@import '../font-awesome/less/font-awesome.less';
body.modal-open {
padding-right: 0 !important;
overflow-y: scroll !important;
}
.modal-dialog {
  top: 100px;
}

#navbar {
  margin: 0;
  .container-fluid {
    max-width: 1430px;
  }
}

.navbar-toggle {
  float:left;
  margin-left: 15px;
  margin-right: 0;
}

h5, h6, .h5, .h6 {
  font-weight: 600;
}

.logo {
  img {
    max-width: 150px;
  }
}

.ProductDisplay {
  .make-lg-column(2.4);
  h3 {
    margin: 0;
    color: #38d469;
  }
  h5 {
    margin-top: 25px;
    min-height: 30px;
  }
  .fa.fa-search {
    font-size: 30px;
  }
  .hover {
    font-size: 12px;
  }
}

/*
@media (min-width: @screen-lg-min) {
	.row-eq > *:nth-child(5n+1) {
		clear: left;
	}
}*/

@media (min-width:@grid-float-breakpoint){
  #block-views-small-shopping-cart-block {
    position: relative;
    top: 40px;
    right: 0;
  }
  #block-locale-language {
    position: relative;
    top: 40px;
  }
}

#block-locale-language {
  font-weight: 600;
  font-size: 16px;
  .language-switcher-locale-url {
     margin-bottom: 0;
    > li {
      display: inline;

      a {
        color: @navbar-default-color;
        text-decoration: none;
        padding-left: 5px;
        padding-right: 5px;
        &.active {
          color: @brand-success;
        }
      }
    }
  }
}

.navbar-brand-centered {
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
}

.navbar-default {
  .menu {
    font-weight: 600;
    font-size: 16px;
  }
  border: 0;
  -webkit-box-shadow: 0 4px 6px -6px #999;
    -moz-box-shadow: 0 4px 6px -6px #999;
    box-shadow: 0 4px 6px -6px #999;
  .navbar-nav > li > {
    text-transform: uppercase;
  }
  @media (min-width: @grid-float-breakpoint) {
    .navbar-nav > li {
      > a {
        border-bottom: 3px solid transparent;
      }
      &.active a {
        border-color: @brand-success;
      }
    }
  }
}

div.highlighted.jumbotron.row {
  margin-bottom: 0;
  padding: 0;
  .block {
    padding: 60px;
  }
}

.jumbotron p {
  font-size: @font-size-base;
}

.footer {
  margin-top: 0;
  h2 {
    .h4;
    text-transform: uppercase;
  }
  .block-title {
    text-transform: uppercase;
    .h4;
    margin-bottom: 30px;
  }

  .menu {
    /*column-count: 2;
    column-gap: 70px;*/
    padding: 0;
    > li > a {
      text-transform: uppercase;
      color: @text-color;
      display: inline-block;
      margin-bottom: 15px;
    }
  }
}

#block-views-frontpage-blocks-block {
  background-color: #f9f9f9;
  text-align: center;
  .img-responsive {
    margin: 0 auto;
  }
  .views-row {
    margin-top: 15px;
    margin-bottom: 15px;
    .views-field-title-field {
      color: #3ed36f;
    }
  }
}

#instablock {
  .App {
    .row();
    > div {
      max-width: 100px;
      img {
        padding-right: 15px;
        padding-bottom: 15px;
      }
    }
  }
}

.form-submit {
  .btn-lg;
}

.not-front .main-container {
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 700px;
}
.page-corridor-carpets .main-container,
.page-custom-carpets .main-container {
	margin-top: 0px;
  	margin-bottom: 0px;
  	max-width: none;
}	

.page-corridor-carpets, .page-custom-carpets {
  margin-top: 40px;
}

@media (min-width: @screen-sm-min) {
  .on-top-of-slide .LenghtForm .form-group input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .on-top-of-slide .LenghtForm .form-group:nth-child(2) input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .on-top-of-slide .LenghtForm button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .LenghtForm .form-inline .form-control {
    display: inline-block;
    vertical-align: initial;
  }
}

/* Cart */
#block-views-small-shopping-cart-block{
  .view-header .line-item-quantity {
    background: @brand-success;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    color: #fff;
    font-size: 80%;
    position: absolute;
    top: -10px;
    left: 40px;
    line-height: 20px;
  }
  .cart-empty-block .empty-cart {
    background: @brand-success;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    color: #fff;
    font-size: 80%;
    position: absolute;
    top: -10px;
    left: 50px;
    line-height: 20px;
  }
}

.throbber {
  position: absolute;
}

.drawer {
  .LenghtForm {
    input, button { width: 100%; max-width: 100%; }
  }
}

@media (max-width: @screen-xs-max){
  #myCarousel .carousel-caption h1 {
      font-size: 30px;
  }
}


.form-group:last-child, .panel:last-child {
  margin-bottom: 20px;
}
@media (max-width: @grid-float-breakpoint-max){
  .navbar-collapse {
    clear: both;
  }
  #block-locale-language {
    margin-bottom: 15px;
  }
  #block-views-small-shopping-cart-block {
        margin-top: -25px;
        .view-small-shopping-cart {
          top: -15px;
        }
  }
  .logo img {
    max-width: 120px;
  }
}

#myCarousel {
  .bg-carousel-image {
    max-height: ~"calc(100vh - @navbar-height)";
  }
}

@media (min-width: @grid-float-breakpoint) {
  @navbar-height: 100px;
  #myCarousel {
    .bg-carousel-image {
      max-height: ~"calc(100vh - @navbar-height)";
    }
  }

  @navbar-padding-vertical:  ((@navbar-height - @line-height-computed) / 2);
  .navbar-fixed-top {
    min-height: @navbar-height;
  }
  .navbar-nav > li > a {
    padding-top: @navbar-padding-vertical;
    padding-bottom: @navbar-padding-vertical;
  }
  .navbar-btn {
    margin-top: 33px;
    margin-bottom: 33px;
  }
}

#sliding-popup {
  .popup-content {
    padding-top: 10px;
    #popup-buttons button {
      .btn;
      .btn-default;
      background-color: transparent;
      padding: 8px 25px;
      color: #fff;
      border-color: #fff;
      text-shadow: none;
      &:hover, &:focus {
        background-color: transparent;
        border-color: #fff;
      }
    }
    .popup-text {
      a {
        text-decoration: underline;
      }
    }
  }
}


/*  Large Desktop  */
@media (min-width:1200px){

  /* Column clear fix */
  .col-md-1:nth-child(12n+1),
  .col-md-2:nth-child(6n+1),
  .col-md-3:nth-child(4n+1),
  .col-md-4:nth-child(3n+1),
  .col-md-6:nth-child(2n+1),
  .col-sm-1:nth-child(12n+1),
  .col-sm-2:nth-child(6n+1),
  .col-sm-3:nth-child(4n+1),
  .col-sm-4:nth-child(3n+1),
  .col-sm-6:nth-child(2n+1){
    clear: none;
  }
  .col-lg-1:nth-child(12n+1),
  .col-lg-2:nth-child(6n+1),
  .col-lg-3:nth-child(5n+1), /* Overridden */
  .col-lg-4:nth-child(3n+1),
  .col-lg-6:nth-child(2n+1){
    clear: left;
  }
}


img.media-element {
  max-width: 100%;
  height: auto;
}

#block-views-frontpage-slides-block {
  position: absolute;
  text-indent: -9999px;
  font-size: 0;
}

.bg-carousel-image {
  min-height: 720px;
}
#views-bootstrap-carousel-1 {
  .row();
}
#views-bootstrap-carousel-1 .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: auto;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  text-shadow: 1px 2px 3px rgba(0,0,0,0.8);
  top: 45%;
  -webkit-transform: translateY(-45%);
  -moz-transform: translateY(-45%);
  transform: translateY(-45%);
}
@media (min-width: 768px) {
  #views-bootstrap-carousel-1 .carousel-caption {
    max-width: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

#views-bootstrap-carousel-1 .carousel-caption p {
  margin-bottom: 0;
}

#block-views-frontpage-linkblocks-block {
  background-color: #fff;
  padding: 0;
  .view-content {
    .row {
      margin: 0;
      @media (min-width: @screen-sm-min) {
        display: flex;
        flex-direction: row;
      }
      > div {
        text-align: center;
        padding: 0;
        position: relative;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        &:first-child {
          @media (min-width: @screen-sm-min) {
            border-right: 1px solid #f0f0f0;
          }
        }
        .views-field.views-field-field-image {
          position: absolute;
          top:0;
          bottom: 0;
          left: 0;
          right: 0;
          .link-block-bg {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }
          div {
            height: 100%;
          }
        }
        .views-field.views-field-body {
          position: relative;
          z-index: 1;
          text-transform: uppercase;
          .field-content {
            background-color: rgba(255,255,255,0.4);
            transition: all .3s linear;
          }
        }
        &:hover {
          .views-field.views-field-body {
            .field-content {
              background-color: rgba(255,255,255,0.6);
            }
          }
        }
        .views-field .field-content > a {
          display: block;
          padding: 90px 15px;
          text-decoration: none;
          color: @text-color;
        }
      }
    }
  }
}
