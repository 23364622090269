// This is almost an exact duplication of the bootstrap.less file provided
// by the Bootstrap Framework itself. The only difference from that file and
// this one are the first two @import statements for the original framework's
// variables and this theme's overrides of those variables. This is necessary
// due to the fact that the Less pre-compiler language does not have the
// concept of "default" variables.
//
// @see https://www.drupal.org/node/2775481
@import "../bootstrap/less/variables.less";
@import "variable-overrides.less";

// Mixins.
@import "../bootstrap/less/mixins.less";

// Reset and dependencies
@import "../bootstrap/less/normalize.less";
@import "../bootstrap/less/print.less";
@import "../bootstrap/less/glyphicons.less";

// Core CSS
@import "../bootstrap/less/scaffolding.less";
@import "../bootstrap/less/type.less";
@import "../bootstrap/less/code.less";
@import "../bootstrap/less/grid.less";

@import "../bootstrap/less/tables.less";
@import "../bootstrap/less/forms.less";
@import "../bootstrap/less/buttons.less";

// Components
@import "../bootstrap/less/component-animations.less";
@import "../bootstrap/less/dropdowns.less";
@import "../bootstrap/less/button-groups.less";
@import "../bootstrap/less/input-groups.less";
@import "../bootstrap/less/navs.less";
@import "../bootstrap/less/navbar.less";
@import "../bootstrap/less/breadcrumbs.less";
@import "../bootstrap/less/pagination.less";
@import "../bootstrap/less/pager.less";
@import "../bootstrap/less/labels.less";
@import "../bootstrap/less/badges.less";
@import "../bootstrap/less/jumbotron.less";
@import "../bootstrap/less/thumbnails.less";
@import "../bootstrap/less/alerts.less";
@import "../bootstrap/less/progress-bars.less";
@import "../bootstrap/less/media.less";
@import "../bootstrap/less/list-group.less";
@import "../bootstrap/less/panels.less";
@import "../bootstrap/less/responsive-embed.less";
@import "../bootstrap/less/wells.less";
@import "../bootstrap/less/close.less";

// Components w/ JavaScript
@import "../bootstrap/less/modals.less";
@import "../bootstrap/less/tooltip.less";
@import "../bootstrap/less/popovers.less";
@import "../bootstrap/less/carousel.less";

// Utility classes
@import "../bootstrap/less/utilities.less";
@import "../bootstrap/less/responsive-utilities.less";


/*  Bootstrap Clearfix */

/*  Tablet  */
@media (min-width:767px){

  /* Column clear fix */
  .col-lg-1:nth-child(12n+1),
  .col-lg-2:nth-child(6n+1),
  .col-lg-3:nth-child(4n+1),
  .col-lg-4:nth-child(3n+1),
  .col-lg-6:nth-child(2n+1),
  .col-md-1:nth-child(12n+1),
  .col-md-2:nth-child(6n+1),
  .col-md-3:nth-child(4n+1),
  .col-md-4:nth-child(3n+1),
  .col-md-6:nth-child(2n+1){
    clear: none;
  }
  .col-sm-1:nth-child(12n+1),
  .col-sm-2:nth-child(6n+1),
  .col-sm-3:nth-child(4n+1),
  .col-sm-4:nth-child(3n+1),
  .col-sm-6:nth-child(2n+1){
    clear: left;
  }
}


/*  Medium Desktop  */
@media (min-width:992px){

  /* Column clear fix */
  .col-lg-1:nth-child(12n+1),
  .col-lg-2:nth-child(6n+1),
  .col-lg-3:nth-child(4n+1),
  .col-lg-4:nth-child(3n+1),
  .col-lg-6:nth-child(2n+1),
  .col-sm-1:nth-child(12n+1),
  .col-sm-2:nth-child(6n+1),
  .col-sm-3:nth-child(4n+1),
  .col-sm-4:nth-child(3n+1),
  .col-sm-6:nth-child(2n+1){
    clear: none;
  }
  .col-md-1:nth-child(12n+1),
  .col-md-2:nth-child(6n+1),
  .col-md-3:nth-child(4n+1),
  .col-md-4:nth-child(3n+1),
  .col-md-6:nth-child(2n+1){
    clear: left;
  }
}


/*  Large Desktop  */
@media (min-width:1200px){

  /* Column clear fix */
  .col-md-1:nth-child(12n+1),
  .col-md-2:nth-child(6n+1),
  .col-md-3:nth-child(4n+1),
  .col-md-4:nth-child(3n+1),
  .col-md-6:nth-child(2n+1),
  .col-sm-1:nth-child(12n+1),
  .col-sm-2:nth-child(6n+1),
  .col-sm-3:nth-child(4n+1),
  .col-sm-4:nth-child(3n+1),
  .col-sm-6:nth-child(2n+1){
    clear: none;
  }
  .col-lg-1:nth-child(12n+1),
  .col-lg-2:nth-child(6n+1),
  .col-lg-3:nth-child(4n+1),
  .col-lg-4:nth-child(3n+1),
  .col-lg-6:nth-child(2n+1){
    clear: left;
  }
}
